<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" no-gutters>
      <v-col cols="12" class="text-center pb-16">
        <v-img
          max-height="300px"
          src="@/assets/images/company.jpg"
        >
        </v-img>
      </v-col>
      <v-col cols="10" class="text-center pb-16 text-h6">
        건강과 관련된 다양한 플랫폼을 개발하는 회사로 2020년 설립했습니다.
        <br /><br />

        첫 프로젝트는 ‘헬스 앤 헬스케어 시스템’으로,
        의원 및 병원급 의료기관의 전문성을 살리는 환자 의뢰 플랫폼 ‘닥터리퍼’를 개발했습니다.<br />
        환자가 가까운 곳에서 쉽고 빠르게 전문 진료를 받을 수 있도록
        의사가 좋은 의사를 찾아 환자를 의뢰하는 플랫폼입니다.<br />
        건강한 삶과 건강한 사회를 위해 좋은 플랫폼을 만들어 가겠습니다.
      </v-col>
      <v-col cols="10" xl="8" class="text-center pt-16 pb-16 font-weight-bold">
        <v-row>
          <v-col cols="12" lg="4" xl="4" class="text-h3 text-left">
            기술과 사람의 가치를 함께하는 헬스앤모어
          </v-col>
          <v-col cols="12" lg="4" xl="4" class="text-left text-h6">
            헬스앤모어는 의사간 연계를 활성화할 수 있도록
            <span class="font-weight-bold">Connect&Provide</span>를 바탕으로
            효율적인 의료 커뮤니티 및 서비스를 제공하는 것을 기업이념으로 하고
            있습니다. <br /><br /><br /><br /><br />
            헬스앤모어의 플랫폼을 활용하여 개인병원간의 진료의뢰 및 병원 홍보,
            진료과 검색 등 다향한 편의 기능을 제공하고, 진료의뢰를 통해 환자가
            가장 이상적이고 적합한 의료 서비스를 받을 수 있습니다.
          </v-col>
          <v-col cols="12" lg="4" xl="4" class="text-left text-h6">
            헬스앤모어는 다양한 방면으로의 IT 기술을 도입하고 있으며, 고객의
            <span class="font-weight-bold">Needs</span>에 맞춘 이상적인 최적의
            시스템을 구축하는 것을 지향하고 있습니다. 또한, 자율적이고 수평적인
            근무환경으로 최선의 해결책을 찾기 위해 적극적으로 토론하고, 가장
            효율적인 문제 해결을 위해 협업하고 이행합니다. <br /><br /><br />
            <span class="font-weight-bold"
              >귀사의 궁극적인 목표에 도달할 수 있도록 활발한 의사소통과 최신
              트렌드에 맞춘 미래지향 플랫폼을 개발할것을 약속합니다.</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
