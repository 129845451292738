<template>
  <company-component />
</template>

<script>
import CompanyComponent from "../../components/introduce/CompanyComponent.vue";
export default {
  components: { CompanyComponent },
  mounted() {
    this.$emit("set-bar", "회사소개");
  },
};
</script>

<style></style>
